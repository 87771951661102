import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../modulos/login/login.service';
import { api } from '../../environments/environment';

export interface IHttpRequestOptions {
  headers?: HttpHeaders;
  context?: HttpContext;
  observe?: 'body' | 'events' | 'response' | any;
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'text' | 'json' | any;
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient, private loginService: LoginService) {}

  public get(
    ruta: string,
    options?: IHttpRequestOptions,
    noCache = false
  ): Observable<any> {
    return new Observable((observer) => {
      this._get(ruta, options, noCache)
        .then((resp) => {
          observer.next(resp);
          observer.complete();
        })
        .catch(async (err) => {
          if (err.error.message === 'Invalid token: access token has expired') {
            await this.loginService.refreshToken();
            this._get(ruta, options, noCache)
              .then((resp) => {
                observer.next(resp);
                observer.complete();
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public post(ruta: string, body: any): Observable<any> {
    return new Observable((observer) => {
      this._post(ruta, body)
        .then((resp) => {
          observer.next(resp);
        })
        .catch(async (err) => {
          if (err.error.message === 'Invalid token: access token has expired') {
            await this.loginService.refreshToken();
            this._post(ruta, body)
              .then((resp) => {
                observer.next(resp);
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public put(ruta: string, body: any): Observable<any> {
    return new Observable((observer) => {
      this._put(ruta, body)
        .then((resp) => {
          observer.next(resp);
        })
        .catch(async (err) => {
          if (err.error.message === 'Invalid token: access token has expired') {
            await this.loginService.refreshToken();
            this._put(ruta, body)
              .then((resp) => {
                observer.next(resp);
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public delete(ruta: string): Observable<any> {
    return new Observable((observer) => {
      this._delete(ruta)
        .then((resp) => {
          observer.next(resp);
        })
        .catch(async (err) => {
          if (err.error.message === 'Invalid token: access token has expired') {
            await this.loginService.refreshToken();
            this._delete(ruta)
              .then((resp) => {
                observer.next(resp);
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  private _get<T>(
    ruta: string,
    options?: IHttpRequestOptions,
    noCache = false
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      // Seteo el header de autorizacion
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.loginService.getToken()}`,
      });
      if (!options) {
        options = {};
      }
      if (options.headers) {
        options.headers = options.headers.append(
          'Authorization',
          'Bearer ' + this.loginService.getToken()
        );
      } else {
        options.headers = headers;
      }
      if (noCache) {
        options.headers = options.headers.append('no-cache', 'true');
      }

      // Request
      this.http.get<T>(`${api}${ruta}`, options).subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private _post(ruta: string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.loginService.getToken()}`,
      });
      const req = this.http.post<any>(`${api}${ruta}`, body, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private _put(ruta: string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.loginService.getToken()}`,
      });
      const req = this.http.put<any>(`${api}${ruta}`, body, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private _delete(ruta: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.loginService.getToken()}`,
      });
      const req = this.http.delete<any>(`${api}${ruta}`, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
