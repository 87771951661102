<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <!-- Menu -->
    <div fxLayout="row" fxLayoutAlign="start center" style="padding: 0 16px">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="(isHandset$ | async) === false"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <mat-toolbar>Menu</mat-toolbar>
    </div>
    <!-- Menu -->

    <!-- Usuario -->
    <div class="usuario">
      <h3 style="text-align: center">{{ usuario?.username }}</h3>
    </div>
    <!-- Usuario -->

    <!-- Elementos Menu -->
    <mat-nav-list>
      <a mat-list-item [routerLink]="'landing'" [routerLinkActive]="'active'">
        Inicio
      </a>
      <a mat-list-item [routerLink]="'clientes'" [routerLinkActive]="'active'">
        Clientes
      </a>
      <a mat-list-item [routerLink]="'usuarios'" [routerLinkActive]="'active'">
        Usuarios
      </a>
      <a
        mat-list-item
        [routerLink]="'log-twilio'"
        [routerLinkActive]="'active'"
      >
        Logs Twilio
      </a>
      <a
        mat-list-item
        [routerLink]="'dispositivos'"
        [routerLinkActive]="'active'"
      >
        Dispositivos
      </a>
      <a mat-list-item [routerLink]="'logNuc'" [routerLinkActive]="'active'">
        Logs Datos 4g
      </a>
      <a mat-list-item [routerLink]="'lotes'" [routerLinkActive]="'active'">
        Lotes
      </a>
      <a
        mat-list-item
        [routerLink]="'tipoDispositivos'"
        [routerLinkActive]="'active'"
      >
        Tipos Disp.
      </a>
      <a
        mat-list-item
        [routerLink]="'loraServers'"
        [routerLinkActive]="'active'"
      >
        Lora Servers
      </a>
      <a mat-list-item (click)="logout()">Salir</a>
    </mat-nav-list>
    <!-- Elementos Menu -->

    <!-- Version -->
    <mat-divider></mat-divider>
    <h3 style="text-align: center; margin-top: 1em; padding: 0 16px">
      <span> Versión: {{ version }}</span>
      <span *ngIf="env !== 'Prod'"> ({{ env }})</span>
    </h3>
    <!-- Version -->
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="(isHandset$ | async) || !drawer.opened"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>HORATECH UTILITIES ADMIN</span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
